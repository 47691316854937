import './css/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright, faDisplay, faEnvelope, xTwitter } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
function Footer() {
    return (
        <div>
            <div id="footer">
                <div id="logo-part">
                    <div id="logo-img"></div>
                </div>
                <div id="right-Contener">
                    <div className="footer-content">
                        <h2>OUR SERVICES</h2>
                        <p className='deta'>software development</p>
                        <p className='deta'>Website development</p>
                        <p className='deta'>managed it service</p>
                        <p className='deta'>data snd analytics</p>
                        <p className='deta'>consulting</p>
                        <p className='deta'>cybersecurity</p>
                    </div>
                    <div className="footer-content">
                        <h2>SUPPORT</h2>
                        <p className="deta">terms of use</p>
                        <p className="deta">privacy</p>
                        <p className="deta">disclaimer</p>
                        <p className="deta">contact</p>
                    </div>
                    <div className="footer-content"><h2>CONTACT US</h2>
                        <div id="logos">
                            <div className="logo-imgs"><FontAwesomeIcon icon=
                                {faXTwitter} /></div>
                            <div className="logo-imgs"><FontAwesomeIcon icon=
                                {faInstagram} /></div>
                            <div className="logo-imgs"><FontAwesomeIcon icon=
                                {faFacebook} /></div>
                            <div className="logo-imgs"><FontAwesomeIcon icon=
                                {faLinkedin} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lastLine"><FontAwesomeIcon icon={faCopyright} /> 2024 BTB TECH LABS PRIVATE LIMITED</div>

        </div>
    )
}
export default Footer;