import './css/Contact_us.css';

function Forth() {
    return (
        <>
            <div id="Forth">
                <h1 id='get-con'>GET IN TOUCH</h1>
                <div id="left-cont"></div>
                <div id="right-cont">
                    <input className='First ' type="text" name="First-name" id="First-name" placeholder='First Name' />
                    <input className='First marg' type="text" name="Last-name" id="Last-name" placeholder='Last Name' />
                    <input className='First marg' type="email" name="email" id="email" placeholder='Work Email' />
                    <textarea name="Massage" id="Massage" cols="30" rows="10" placeholder='Please Tell Us How We Can Help You'></textarea>
                    <button id="sub-btn">Submit</button>
                </div>
            </div>
        </>
    )
}
// const myArr =['apply','banana','cat']
// const mybas=myArr.map((item)=><p>{item}</p>)
// const con = document.getElementById("root");
// const root = ReactDOM.createRoot(con)
export default Forth;