import './css/LandingPage.css';
function First() {
    return (<>
        <div id='Back-img'>
            <p className='pera'>IT solution for the new era</p>
            <div id='contener'>
                <h1 id='we-make'>We build software that grow up</h1>
                <h1 id='your-buniness'>Your Business</h1>
                <button id='connect'>
                    <span>Connect</span>
                </button>
            </div>

        </div>

    </>
    );
}
export default First;