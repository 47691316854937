import './css/AboutUs.css';
function Third() {
    return (
        <>
            <div id="forthContener">
                <h2 id='About'>ABOUT US</h2>
                <h1 className='content'>we are the best <span>software development company</span> in the world</h1>
                <div id="main">
                    <div id="leftContener"></div>
                    <div id="rightContener">
        
                        <p className="paragraph"> BTB Tech leads the charge as a pioneering software development firm, specializing in revolutionary solutions for web, mobile, and enterprise platforms. Supported by a seasoned team of industry leaders and cutting-edge technologies, we provide bespoke software solutions that drive business growth.</p>
                        <p className="paragraph"> Our steadfast commitment to excellence and client satisfaction sets us apart from the competition. Whether you're a startup or a corporate giant, BTB Tech is your trusted ally for all software development needs</p>
                        <a href="#" id='btn'> <span>More</span></a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Third;