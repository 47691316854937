import './css/App.css';

function Navbar() {
  return (
    <>
      <div className='mainContener'>
        <div id='Item'>
          <a href='#' id='btblogo'></a>
          <div id='NavItem'>
            <a className='nav' href='#'>HOME</a>
            <a className='nav' href='#'>OUR SERVICE</a>
            <a className='nav' href='#'>ABOUT US</a>
            <a className='nav' href='#'>CONTACT US</a>
          </div>
        </div>
      </div>
    </>
  )
}
export default Navbar;