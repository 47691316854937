// import logo from './logo.svg';

import LandingPage from './LandingPage';
import Footer from './Footer';
import Contact from './Contact_us';
import AboutUs from './AboutUs';
import Navbar from './Navbar';
import OurService from './OurService';


function App() {
  return (
    <div>
      <Navbar />
      <LandingPage />
      <OurService />
      <AboutUs />
      <Contact />
      <Footer />
    </div>
  );
}
export default App;
