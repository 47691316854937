import './css/OurService.css';
function Secound() {
    return (
        <>
            <div id="third-continer">
                <p className='our-service'>OUR SERVICES</p>
                <h1 className='Best-service'>we ensure the <span>best services</span> for our clients </h1>
                <div className="continer">
                    <div className='main '>
                        <div className="img1 man"></div>
                        <h1 className="heading">Software Development</h1>
                        <p className="pera1">Cutting-edge software development solutions tailored for businesses, driving growth efficiently.</p>
                    </div>
                    <div className='main '>
                        <div className="img2  man"></div>
                        <h1 className="heading"> Website Development</h1>
                        <p className="pera1">Unlock your online potential with cutting-edge website development solutions today.</p>
                    </div>
                    <div className='main '>
                        <div className="img3 man"></div>
                        <h1 className=" heading3">Managed IT Service</h1>
                        <p className="pera1">Boost efficiency and productivity with our comprehensive Managed IT Services today.</p>
                    </div>

                </div>
            </div>
        </>
    );
}
export default Secound;
